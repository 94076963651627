export default function () {
  const $offCanvas = $("[data-off-canvas]");
  $offCanvas.on("opened.zf.offCanvas", (e) => {
    $(e.currentTarget).find('.menu:first li:first a').focus();
    $('body').addClass('off-canvas-open');
	});
  $offCanvas.on("close.zf.offCanvas", () => {
    $('body').removeClass('off-canvas-open');
	});
}
