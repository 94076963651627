export default function () {
  $(document).on('open.zf.reveal', '[data-reveal]', function () {
    const widthDifference = window.innerWidth - $('body').prop('clientWidth');
    $('html').css('padding-right', widthDifference).addClass('reveal-open');
    $('.header.fixed').css('padding-right', widthDifference);
  });

  $(document).on('closed.zf.reveal', '[data-reveal]', function () {
    $('html').css('padding-right', 0).removeClass('reveal-open');
    $('.header.fixed').css('padding-right', 0);
  });

  $(document).on('open.zf.reveal', '[data-reveal][data-reveal-focus]', function () {
    const modal = $(this);
    window.setTimeout(function () {
      modal.find(modal.data('reveal-focus') || 'input:visible').first().focus();
    }, 400);
  });

  $(document).on('click', '[data-reveal-id]', function () {
    const reveal = $('#' + $(this).data('reveal-id') + '[data-reveal-fill-from-key]');
    if (reveal.length) {
      reveal.find(reveal.data('reveal-fill-from-key')).val($(this).closest('[data-key]').data('key'));
    }
  });

  $(document).on('open.zf.reveal', '[data-reveal]', function () {
    const $modal = $(this);
    const $dataReveal = $('[data-open="' + $modal.attr('id') + '"]');
    const dataRevealAjax = $dataReveal.attr('data-reveal-ajax');
    if (typeof dataRevealAjax !== undefined && dataRevealAjax !== false) {
      const ajaxUrl = $dataReveal.data('reveal-ajax');
      if (ajaxUrl) {
        $.ajax(ajaxUrl).done(function (response) {
          $modal.find('.reveal__inner').html(response);
        });
      }
    }
  });

  $(document).on('click', '[data-open-ajax]', function (e) {
    const $link = $(e.currentTarget);
    const $modal = $('#' + $link.data('open-ajax'));

    e.preventDefault();
    $.ajax($link.attr('href')).done((response) => $modal.html(response).foundation('open'));
  });

}
