import DragonDrop from 'drag-on-drop';

function initialize($gridView) {
  const $container = $gridView.find('table tbody').get(0);

  const dragonDrop = new DragonDrop($container, {
    item: 'tr',
    handle: '.sortable__handle',
    activeClass: 'sortable__item--active',
    inactiveClass: 'sortable__item--inactive',
    announcement: {
      grabbed: (el, items) => (`Item ${items.indexOf(el) + 1} grabbed`),
      dropped: (el, items) => (`Item ${items.indexOf(el) + 1} dropped`),
      reorder: (el, items) => (
        `The list has been reordered, The item is now on ${items.indexOf(el) + 1} of ${items.length}`
      ),
    },
  });

  const updateReorder = (items) => {
    const gridContainer = $('#grid-view-container, #groups-container');
    if (gridContainer) {
      gridContainer.addClass('pjax--loading').attr('aria-busy', 'true');
    }
    $.post(window.location.href, {
      order: items.map((element) => element.dataset.key),
    }).done(() => {
      items.forEach((element, index) => {
        if (element.querySelector('[data-td-title="#"]')) {
          // eslint-disable-next-line no-param-reassign
          element.querySelector('[data-td-title="#"]').innerHTML = index + 1;
        }
      });
      if (gridContainer) {
        gridContainer.removeClass('pjax--loading').attr('aria-busy', 'false');
      }
    });
  };

  const listener = (e) => e.preventDefault();

  dragonDrop.on('dropped', () => {
    updateReorder(dragonDrop.items);
  });

  dragonDrop.dragula.on('drag', () => {
    document.addEventListener('touchmove', listener, { passive: false });
  });

  dragonDrop.dragula.on('dragend', () => {
    document.removeEventListener('touchmove', listener, { passive: false });
  });

  dragonDrop.dragula.on('drop', () => {
    updateReorder(dragonDrop.items);
  });
}

export default function () {
  $('[data-pjax-container]').on('pjax:end', (event) => {
    $('[data-sortable-grid-view]', $(event.currentTarget)).each((index, element) => {
      initialize($(element));
      const sortedHeader = $('[aria-sort="ascending"] a, [aria-sort="descending"] a', $(element));
      sortedHeader.focus();
    });
  });
  $('[data-sortable-grid-view]').each((index, element) => initialize($(element)));
}
