/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce';
import 'tinymce/jquery.tinymce';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';

import 'tinymce/skins/ui/oxide/skin.min.css';

// import standard plugins
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/hr/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/help/plugin';

// import custom plugins
import './tinymce/tinymce-content-tags';

let initialized = false;

function initialize($element, options) {
  const defaultOptions = {
    paste_preprocess: (plugin, args) => {
      args.content = args.content.replace(/&nbsp;&nbsp;/g, '');
    },
  };
  const tinymceOptions = { ...defaultOptions, ...options.tinymceOptions };
  const tox = {
    toolbarOverlord: '.tox-toolbar-overlord',
    tinyMCE: '.tox-tinymce',
  };
  if (options.collapsed) {
    tinymceOptions.setup = (theEditor) => {
      // use focus and blur effect only when creating questions and feedback
      theEditor.on('focus', () => {
        $(theEditor.container)
          .find(tox.toolbarOverlord)
          .slideDown(200)
          .closest(tox.tinyMCE)
          .addClass('tox-tinymce--active');
      });
      theEditor.on('blur', () => {
        $(theEditor.container)
          .find(tox.toolbarOverlord)
          .slideUp(200)
          .closest(tox.tinyMCE)
          .removeClass('tox-tinymce--active');
      });
      theEditor.on('init', () => {
        $(theEditor.editorContainer)
          .find(tox.toolbarOverlord)
          .slideUp(200)
          .closest(tox.tinyMCE)
          .removeClass('tox-tinymce--active');
      });
      theEditor.on('change', () => {
        theEditor.getElement().value = theEditor.getContent();
      });
    };
  } else {
    tinymceOptions.setup = (theEditor) => {
      theEditor.settings.autoresize_min_height = $element.attr('rows') * 15;
      if ($(`#${theEditor.id}`).attr('readonly') || $(`#${theEditor.id}`).attr('disabled')) {
        theEditor.settings.readonly = true;
      }
      theEditor.on('change', () => {
        theEditor.getElement().value = theEditor.getContent();
      });
    };
  }
  if (tinymceOptions.base_url) {
    window.tinymce.baseURL = tinymceOptions.base_url;
  }
  $element.tinymce(tinymceOptions);
}

function initializeAll() {
  $('[data-tinymce]:visible')
    .each((index, element) => initialize($(element), $(element).data('tinymce')));
}

export default () => {
  initializeAll();
  if (!initialized) {
    initialized = true;
    $(document)
      .on('open.zf.reveal', '[data-reveal]', () => initializeAll())
      .on('closed.zf.reveal', '[data-reveal]', (event) => {
        $('[data-tinymce]', $(event.currentTarget)).each((index, element) => {
          $(element).tinymce().remove();
        });
      });

    $('[data-tabs]').on('change.zf.tabs', () => initializeAll());
  }
};
/* eslint-enable no-param-reassign */
