export default function () {
  $('[data-dialcode-container]').each((index, element) => initialize($(element)));
}

function initialize(element) {
  element.on('ready, change', function () {
    updateDialcode(element);
  });
  updateDialcode(element);
}

function updateDialcode(element) {
  const dialcode = element.find(':selected').data('dialcode');
  if (dialcode) {
    $(element.data('dialcode-container')).html(dialcode);
  } else {
    $(element.data('dialcode-container')).html('');
  }
}