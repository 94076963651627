function rebuild(obj) {
  const id = obj.data('replaced-content');
  obj.val(obj._baseValue.replace(/\{[a-z]+}/g, m => $(`[data-replace-content-id="${id}"][data-replace-content-tag="${m}"]`).val()));
}

export default function () {
  $('[data-replaced-content]').each((index, item) => {
    const $item = $(item);
    $item._baseValue = $item.val();

    const id = $item.data('replaced-content');
    const related = $(`[data-replace-content-id="${id}"]`);
    related.on('change', () => rebuild($item));
    rebuild($item);
  });
}
