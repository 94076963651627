export default function () {
  if ($('#forgetPasswordLink').length) {
    $('#forgetPasswordLink').click(showForgetPassword);
  }
}

function showForgetPassword (e) {
  $('#loginCard').hide();
  const $forgotPasswordCard = $('#forgotPasswordCard');
  $forgotPasswordCard.show();
  $forgotPasswordCard.find('input[type="email"]').focus();
  $('#loginAccountText').hide();
}
