function initialize($select) {
  $select.on('change', (e) => {
    const $activeSelect = $(e.currentTarget);
    $.pjax({
      url: $activeSelect.val(),
      container: `#${$activeSelect.closest('[data-pjax-container]').attr('id')}`,
      timeout: 5000,
    });
  });
}

export default function () {
  const initializeElements = () => $('[data-grid-view-select-sorter]').each((index, element) => {
    initialize($(element));
  });
  initializeElements();
  $(document).on('pjax:end', initializeElements);
}
