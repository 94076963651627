function initialize($textinput) {
  const $colorInput = $textinput.prev('[data-color-picker-color-input]');
  $colorInput.val($textinput.val());

  $colorInput.on('input', () => {
    $textinput.val($colorInput.val());
    $textinput.change();
  });

  $textinput.on('input change', () => {
    $colorInput.val($textinput.val());
  });
}

export default function () {
  $('[data-color-picker]').each((index, element) => initialize($(element)));
}
