const $form = $('[data-form-confirm]');
const $submitButton = $form.find('button[type="submit"]').not('[data-button-no-loading]');

export default function() {
  $form.find('button[type="submit"]').each((index, element) => initialize($(element), 'button--loading'));
  $form.find('[data-loading-class]').each((index, element) =>
    initialize($(element), $(element).data('loading-class')));
}

function initialize($element, $class) {
  $submitButton.click(() => $element.addClass($class));
  $form.on('afterValidate', (event, messages, errorAttributes) => {
    if(errorAttributes.length) {
      $element.removeClass($class);
    }
  });
}