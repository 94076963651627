
const $field = $('[data-categories-field]');

export default function () {
  $field.each(() => {
    $field.parents('form').on('beforeValidate', () => {
      writeCategoryArray($field);
    });
    $field.find('tbody.ui-sortable').sortable({
      stop: () => {
        writeCategoryArray($field);
      }
    });
    $field.find('[data-categories-item]').change(() => {
      writeCategoryArray($field);
    })
  });
}

function writeCategoryArray($field) {
  const categoryConfig = [];
  $field.find('[data-categories-item]').each((index, element) => {
    const id = $(element).data('categories-item');
    categoryConfig.push([id, parseInt($(element).val())]);
  });
  $field.find('[data-categories-input]').val(JSON.stringify(categoryConfig));
}