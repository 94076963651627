import refresh from './form/refresh';

export default function() {
  $(document).on('click', '[data-update]', (event) => {
    event.preventDefault();

    const $element = $(event.currentTarget);
    const config = $element.data('update');
    const $target = $(config.target);
    const $value = config.value;

    $target.html($value);
    refresh();
  });
}
