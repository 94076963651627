export default function () {
  $(document).on('change', '.answer__correct input', multipleCheck);
  multipleCheck();
}

function multipleCheck() {
  let multipleAnswerPanel = $('.answer__multiple');
  let correctCount = 0;
  $('.answer__correct input').each(function () {
    if ($(this).is(':checked')) {
      correctCount++;
    }
  });

  if (correctCount > 1 && !multipleAnswerPanel.is(':visible')) {
    multipleAnswerPanel.slideDown(200);
  }
  else if (correctCount <= 1 && multipleAnswerPanel.is(':visible')) {
    multipleAnswerPanel.slideUp(200);
  }

}
