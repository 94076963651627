export default function() {
  const $form = $('[data-form-confirm]');
  if ($form.length) {
    initialize($form);
  }

  $('.disabled input, .disabled select, .disabled textarea, .disabled button')
    .prop('disabled', true)
    .addClass('disabled')
    .removeAttr('data-image-input');
  $('.disabled [data-toggle]')
    .removeAttr('data-toggle');
}

function initialize($form) {
  const initialValue = $form.serialize();
  let value = initialValue;
  let shouldShowAlert = true;
  $form.on('change', ':input', () => value = $form.serialize());

  const beforeUnload = (event) => {
    if (!shouldShowAlert) {
      return undefined;
    }
    if (value != initialValue) {
      event.preventDefault();
      const message = $form.data('form-confirm');
      event.returnValue = message;
      return message;
    }
  };

  $form.on('afterValidate', (event, messages, errorAttributes) => {
    if (!errorAttributes.length) {
      shouldShowAlert = false;
    }
  });
  window.addEventListener('beforeunload', beforeUnload);

}
