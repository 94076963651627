const bulkActionsAnimationDuration = 400;

export default function bulkActions() {
  $('[data-pjax-container]').on('pjax:end', (event) => {
    $('[data-bulk-actions]', $(event.currentTarget)).each((index, element) => initialize($(element)));
  });
  $('[data-bulk-actions]').each((index, element) => initialize($(element)));
}

function initialize($element) {
  const bulkActionOptions = $element.data('bulk-actions');
  const gridViewId = '#' + bulkActionOptions.gridViewId;
  const bulkActionsId = gridViewId + ' [data-bulk-actions]';
  const bulkSelectId = gridViewId + ' [data-bulk-select]';

  insertBulkActionsPanel(gridViewId, bulkActionOptions.bulkActionsPanel);

  $(bulkActionsId).change(function () {
    $(bulkSelectId).prop('checked', $(this).is(":checked"));
  });
  $(bulkSelectId + ', ' + bulkActionsId).change(function () {
    const checked = $(bulkSelectId + ':checked').length;
    const allchecked = checked && !$(bulkSelectId + ':not(:checked)').length;

    if (!$(this).data('bulk-actions')) {
      if (allchecked) {
        $(bulkActionsId).prop('checked', true);
      } else {
        $(bulkActionsId).prop('checked', false);
      }
    }

    if (checked) {
      showBulkActions(gridViewId);
    }
    else {
      hideBulkActions(gridViewId);
    }
  });
  $('.bulkactions__cancel').on('click', function (e) {
    e.preventDefault();
    $(bulkSelectId + ', ' + bulkActionsId).prop('checked', false);
    hideBulkActions(gridViewId);
  });
  $('a[data-bulk-parameters-fill]').on('click', function (e) {
    $('[data-bulk-parameters]').data('bulk-parameters', $(this).data('bulk-parameters-fill'));
  });
}

function showBulkActions(gridViewId) {
  $('html').addClass('bulkactions-open');
  $(gridViewId + ' .bulkactions__count').html($(gridViewId + ' [data-bulk-select]:checked').length);
  if ($(gridViewId + ' .items tbody tr').length > 10) {
    $(gridViewId + ' tr.bulkactions').fadeIn(bulkActionsAnimationDuration);
  }
  else {
    $(gridViewId + ' thead tr.bulkactions').fadeIn(bulkActionsAnimationDuration);
  }
  $(gridViewId + ' tr.filters').addClass('hide-for-medium');
}

function hideBulkActions(gridViewId) {
  $('html').removeClass('bulkactions-open');
  $(gridViewId + ' tr.bulkactions').hide();
  $(gridViewId + ' tr.filters').removeClass('hide-for-medium');
}

function insertBulkActionsPanel(gridViewId, $bulkActionsPanel) {
  if (!$(`${gridViewId} .bulkactions`).length) {
    $(gridViewId + ' thead, ' + gridViewId + ' tfoot').prepend($bulkActionsPanel);
  }

  $(gridViewId).on('click', '[data-bulk]', function (event) {
    const $bulkSelectButton = $(this);
    const pjaxContainer = $bulkSelectButton.data('pjax-container') || '#' + $bulkSelectButton.closest('[data-pjax-container]').attr('id');

    let formData = {};
    let selected = [];

    event.stopImmediatePropagation();
    const message = $bulkSelectButton.data('confirm');
    if (!message || confirm(message)) {

      if ($(this).data('bulk-parameters')) {
        let parameters = $(this).data('bulk-parameters');
        for (let k in parameters) {
          formData[k] = parameters[k];
        }
      }

      $("[data-bulk-select][type=checkbox]:checked").each(function () {
        selected.push($(this).val());
      });
      formData['bulk-select'] = selected;
      console.log(formData);
      $.pjax({
        type: 'POST',
        url: $bulkSelectButton.data('bulk'),
        data: formData,
        container: pjaxContainer,
        push: false,
        beforeSend: function () {
          $(pjaxContainer).addClass('pjax--loading').attr('aria-busy', 'true');
        },
      });
    }
  });
}
