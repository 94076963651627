import refresh from './refresh';

export const refreshAddFormItem = () => {
  $('[data-add-form-item]').each((index, element) => {
    const $addButton = $(element);
    const config = $addButton.data('add-form-item');
    const $container = $(config.container);

    if (config.max) {
      if ($container.children().length >= config.max) {
        $addButton.hide();
      } else {
        $addButton.show();
      }
    }

  });
};

function addItem($addButton) {
  let config = $addButton.data('addFormItem');

  const $container = $(config.container);
  const $form = $addButton.parents('form');

  const $containerWithNewItem = $container
    .hide()
    .append(config.item.replace(new RegExp(config.indexTag, 'g'), config.index))
    .fadeIn(300);

  $containerWithNewItem.children().last().find(':focusable').first().focus();

  refresh();
  enableFormJs($form, config.clientOptions, config.index, config.indexTag);

  ++config.index;
  $addButton.data('add-form-item', config);

  $addButton[0].dispatchEvent(new CustomEvent('OneToMany:itemAdded', {
    bubbles: true,
    detail: {
      container: config.container,
      item: $container[0].lastChild,
      number: $container[0].children.length,
      formName: config.formName,
    },
  }));

  refreshAddFormItem();
}

function removeItem($removeButton) {
  const config = $removeButton.data('removeFormItem');
  const $element = $removeButton.parent();

  $element.remove();

  document.querySelector(config.container).dispatchEvent(new CustomEvent('OneToMany:itemRemoved', {
    bubbles: true,
    detail: {
      container: config.container,
      item: $element[0],
      number: $(config.container)[0].children.length,
      formName: config.formName,
    }
  }));

  refreshAddFormItem();
}

export default function () {
  refreshAddFormItem();

  $(document).on('click', '[data-add-form-item]', (event) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    addItem($(event.currentTarget))
  });

  $(document).on('click', '[data-remove-form-item]', (event) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    removeItem($(event.currentTarget));
  });
}


function enableFormJs($form, clientOptions, index, indexTag) {
  eval(clientOptions).forEach((attributeConfig) => {
    $form.yiiActiveForm(
      'add',
      Object.keys(attributeConfig).reduce((prev, cur) => ({
        ...prev,
        [cur]: typeof attributeConfig[cur] === 'string'
          ? attributeConfig[cur].replace(new RegExp(indexTag, 'g'), index)
          : attributeConfig[cur],
      }), {})
    );
  });
}
