import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

export default function() {
  $('[data-timezone]').each((index, element) => initialize($(element)));
}

function initialize($element) {
  if ($element.is(':input')) {
    $element.val(dayjs.tz.guess());
  } else {
    $element.text(dayjs.tz.guess());
  }
}
