/* eslint no-restricted-globals: 0 */
export default function () {
  const form = $('form');
  form.on('beforeSubmit', () => {
    const action = form.attr('action').replace(/#.*/, '');
    const pathname = $(location).attr('pathname').replace(/#.*/, '');
    const search = $(location).attr('search');
    const hash = $(location).attr('hash');
    const isHashFromTab = form.find(`[role="tab"][href$="${hash}"]`).length;

    if (isHashFromTab && action && pathname && action === `${pathname}${search}`) {
      form.attr('action', `${action}${hash}`);
    }
  });
}
