

export default function() {
  $(document).on('pjax:send', (xhr) => {
    if ($(xhr.relatedTarget).data('pjax-button')) {
      $(xhr.relatedTarget).addClass('button--loading');
    }
  });
  $(document).on('pjax:complete', (xhr) => {
    if ($(xhr.relatedTarget).data('pjax-button')) {
      $(xhr.relatedTarget).removeClass('button--loading');
    }
  });
}
