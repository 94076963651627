export default function () {
  $(document).on('pjax:complete', () => {
    const widget = document.getElementById('modal--users-imported');
    const button = document.getElementById('show-users-import-result');
    if (widget && button) {
      button.classList.remove('hidden');
    } else if (button) {
      button.classList.add('hidden');
    }
  });
}
