
function setToggleInputActiveClass($input) {
	var inputName = $input.attr('name');
	$('input[name="' + inputName + '"]').closest('label').removeClass('active').addClass('inactive');
	$('input[name="' + inputName + '"]:checked').closest('label').removeClass('inactive').addClass('active');
}
export default function toggleInput() {
	$('.formcontrol__list input:checked').each(function () {
		setToggleInputActiveClass($(this));
	});
	$('.formcontrol__list input').change(function () {
		setToggleInputActiveClass($(this));
	});
}
