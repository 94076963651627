export default function () {
  $("[data-editor]").each((index, div) => {
    // Implementation inspired by https://gist.github.com/duncansmart/5267653
    const options = $(div).data('editor');
    const textarea = document.getElementById(options.for);
    const editor = ace.edit(div);

    editor.setOptions({
      minLines: options.minLines,
      maxLines: options.maxLines,
    });
    editor.getSession().setMode("ace/mode/" + options.mode);
    editor.setTheme("ace/theme/" + options.theme);
    editor.getSession().setTabSize(4);
    editor.setShowPrintMargin(false);
    editor.getSession().setValue(textarea.value);

    // copy back to textarea on form submit...
    $(textarea.form).submit(function () {
      textarea.value = editor.getSession().getValue();
    });
    $(textarea).hide();
  });
}