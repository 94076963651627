import { english } from 'flatpickr/dist/l10n/default';
import { Danish } from 'flatpickr/dist/l10n/da';
import { German } from 'flatpickr/dist/l10n/de';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { French } from 'flatpickr/dist/l10n/fr';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { Norwegian } from 'flatpickr/dist/l10n/no';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { Swedish } from 'flatpickr/dist/l10n/sv';

const langMap = {
  da: Danish,
  de: German,
  es: Spanish,
  fr: French,
  ru: Russian,
  nl: Dutch,
  no: Norwegian,
  pt: Portuguese,
  sv: Swedish,
  en: english,
};

// fix input blur label bug
function materializeFlatpickr($input) {
  $input.closest('.formcontrol--materialize').addClass('formcontrol--hasflatpickr');
}

function deMaterializeFlatpickr($input) {
  if (!$input.val()) {
    $input.closest('.formcontrol--materialize').removeClass('formcontrol--hasflatpickr');
  }
}

function initialize($input) {
  const { lc } = $input.data('datepicker');

  $input.flatpickr($.extend({}, {
    locale: langMap[lc],
    defaultDate: $input.val(),
    allowInput: true,
    altInput: true,
    onOpen: () => materializeFlatpickr($input),
    onClose: () => deMaterializeFlatpickr($input),
    onReady: (dateObj, dateStr, instance) => {
      const $clearDateButton = $(instance.input).closest('div').find('[data-flatpicker-clear]');
      if ($clearDateButton) {
        $clearDateButton.on('click', () => {
          instance.clear();
          instance.close();
        });
      }
    },
  }, $input.data('datepicker')));
}

export default function () {
  $('[data-datepicker]').each((index, element) => initialize($(element)));
  $(document).on('pjax:complete', () => {
    $('[data-datepicker]').each((index, element) => initialize($(element)));
  });
}
