export default function () {
  if ($('[data-reveal-refresh]').length) {
    initialize();
  }
}

function initialize() {
  $(document).on('closed.zf.reveal', '[data-reveal]', function() {
    $.pjax({  container: '#grid-view-container', timeout: 5000});
  });
}
