const select = $('[data-maxanswers-observe]');
const options = select.prop('options');
const minimalNumberOfOptions = 1;

function addOption(numberOfItems) {
  if (options.length < numberOfItems) {
    options.add(new Option(options.length, options.length));
  }
}

function removeOption() {
  if (options.length > minimalNumberOfOptions) {
    options.remove(options.length - 1);
  }
}

function initialize() {
  document.addEventListener('OneToMany:itemAdded', (event) => {
    if (event.detail.formName === select.data('maxanswersObserve')) {
      addOption(event.detail.number);
    }
  });

  document.addEventListener('OneToMany:itemRemoved', (event) => {
    if (event.detail.formName === select.data('maxanswersObserve')) {
      removeOption();
    }
  });
}

export default function () {
  if (select.length) {
    initialize();
  }
}
