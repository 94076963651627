export default function () {
  if ($('[data-pjax-refresh]').length) {
    initialize();
  }
}

function initialize() {
  $(document).on('pjax:success', function (xhr) {
    const $target = $(xhr.target);
    if ($target.length && $target.data('pjax-refresh')) {
      $.pjax({ url: window.location.href, container: $target.data('pjax-refresh'), timeout: 5000 });
    }
  });
  $(document).on('click', 'a[data-pjax-refresh], button[data-pjax-refresh]', function() {
    const $target = $(this);
    let url = $target.data('pjax-refresh-url');
    if (url === undefined) {
      url = window.location.href;
    }
    let enablePushState = $target.data('pjax-enable-push-state');
    if (enablePushState === undefined) {
      enablePushState = true;
    }
    $.pjax({ url: url, container: $target.data('pjax-refresh'), push: enablePushState, timeout: 5000});
  });
}
