export default function () {
  $('[data-popup-radio]').each((index, element) => initialize($(element)));
}

function initialize($element) {
  $element.on('change, click', () => {
    $($element.data('popup-radio')).html(
      $element
        .parent()
        .clone()
        .children('input')
        .remove()
        .end()
        .html()
    );
  });

  $element.keydown(function(e) {
    if(e.keyCode === 13) {
      $('[data-reveal]').foundation('close');
      e.preventDefault();
    }
  });
}
