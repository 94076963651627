function validateRequiredInputs($container, $form, $inputs) {
  let timesValidated = 0;
  let validated = true;

  $inputs.each((index, element) => {
    $form.yiiActiveForm('validateAttribute', element.id);
  });

  $form.on('afterValidateAttribute', (event, attribute, messages) => {
    if (messages.length) {
      validated = false;
    }
    timesValidated += 1;
    if ($inputs.length === timesValidated && validated) {
      const { revealId } = $container.data();
      $(`#${revealId}`).foundation('open');
    }
  });
}

export default () => {
  $('form .reveal [data-sub-validate=true][data-toggle]').click((event) => {
    const $container = $(event.currentTarget);
    const $form = $container.form();
    const $inputs = $container.closest('div.reveal').find('[aria-required=true]');

    if ($inputs.length) {
      event.stopImmediatePropagation();
      validateRequiredInputs($container, $form, $inputs);
    }
  });
};
