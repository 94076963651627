import Cropper from 'cropperjs';
import { isCrossOriginURL } from 'cropperjs/src/js/utilities';
import {
  closeImageReveal, cssClass, imageError, openImageReveal,
} from './util';

function fixCrossOriginUrl() {
  const canvasImg = window.document.querySelector('img.cropper-hide');
  const src = canvasImg.getAttribute('src');
  if (isCrossOriginURL(src)) {
    canvasImg.setAttribute('crossorigin', 'anonymous');
    canvasImg.setAttribute('src', src);
  }
}

export default function () {
  const CROPPER_VIEW_MODE_NO_CROP_RESTRICTIONS = 0;
  const CROPPER_VIEW_MODE_CROP_RESTRICTED_TO_CANVAS = 1;

  $('[data-image-input]').each((index, element) => {
    const $element = $(element);

    if ($element.data('image-input-enabled')) {
      return;
    }

    $element.data('image-input-enabled', true);
    const config = $element.data('image-input');
    const $addImageButton = $(`button[data-cropit-id="${config.id}"]`);
    const previewImage = document.getElementById(`preview-${config.id}`);
    const originalImage = new Image();
    let cropper;
    let cropBoxData = {};
    let canvasData = {};
    let previouslyCroppedImage;

    if ($element.find('img.cropit-image').length) {
      $element.addClass(cssClass.hasimage);
      $addImageButton.hide();
      previewImage.src = $element.find('img.cropit-image').attr('src');
      $element.find('img.cropit-image')[0].onload = () => {
        $('.cropit-wrapper', $element).css({
          maxWidth: $element.find('img.cropit-image').get(0).naturalWidth,
        });
        $('.cropit-spacer', $element).hide();
      };
    }

    const input = document.getElementById(config.id);

    function getContainerDimensions() {
      const aspectRatio = Math.max(config.width, config.height)
        / Math.min(config.width, config.height);
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      ) * 0.7;
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      ) * 0.7;
      const useClientViewPort = (config.width > vw) || (config.height > vh);
      let containerWidth;
      let containerHeight;
      if (useClientViewPort) {
        if (config.width > config.height) {
          containerWidth = vw;
          containerHeight = vw / aspectRatio;
        } else {
          containerWidth = vh / aspectRatio;
          containerHeight = vh;
        }
      } else {
        containerWidth = config.width;
        containerHeight = config.height;
      }

      return {
        useClientViewPort,
        width: containerWidth,
        height: containerHeight,
      };
    }

    function setCanvasAndCropperSize(containerDimensions) {
      if (cropBoxData.scaleX) {
        cropper.setData({
          ...cropBoxData,
          scaleX: config.width / cropBoxData.width,
          scaleY: config.height / cropBoxData.height,
        });
        cropper.setCanvasData(canvasData);
      } else {
        const fillWidth = (cropper.getImageData().naturalWidth / config.width)
          < (cropper.getImageData().naturalHeight / config.height);

        if (fillWidth) {
          cropper.setCanvasData({
            width: containerDimensions.width,
            left: 0,
          });
        } else {
          cropper.setCanvasData({
            height: containerDimensions.height,
            top: 0,
          });
        }
      }

      cropper.setCropBoxData({
        width: containerDimensions.width,
        height: containerDimensions.height,
        left: 0,
        top: 0,
      });
    }

    /**
     *
     * @param {number} viewMode
     * @param {Function} callback
     */
    function createCropper(viewMode, callback = undefined) {
      const containerDimensions = getContainerDimensions();
      $element.find('.cropit-wrapper').css('width', containerDimensions.width);
      $element.find('.cropit-wrapper').css('height', containerDimensions.height);
      if (previewImage.src !== '') {
        previouslyCroppedImage = previewImage.src;
      }
      if (originalImage.src !== '') {
        previewImage.src = originalImage.src;
      }
      cropper = new Cropper(previewImage, {
        viewMode,
        dragMode: 'move',
        cropBoxMovable: false,
        cropBoxResizable: false,
        aspectRatio: config.width / config.height,
        minContainerWidth: containerDimensions.width,
        minContainerHeight: containerDimensions.height,
        minCropBoxWidth: containerDimensions.width,
        minCropBoxHeight: containerDimensions.height,
        checkCrossOrigin: false,
        crop: fixCrossOriginUrl,
        ready() {
          setCanvasAndCropperSize(containerDimensions);
          if (callback) {
            callback($element);
          }
        },
        zoom(event) {
          if (event.detail.ratio > event.detail.oldRatio
            && config.width / cropper.getCanvasData().naturalWidth
            > cropper.getData().width / cropper.getCanvasData().width) {
            event.preventDefault();
          }
        },
      });
    }

    /**
     *
     * @param {number} viewMode
     * @param {Function} callback
     */
    function createCropperWithoutAspectRatio(viewMode, callback = undefined) {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      ) * 0.7;
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      ) * 0.7;
      $element.find('.cropit-wrapper').css('width', Math.min(vw, config.width));
      $element.find('.cropit-preview').css('max-height', vh);
      if (previewImage.src !== '') {
        previouslyCroppedImage = previewImage.src;
      }
      if (originalImage.src !== '') {
        previewImage.src = originalImage.src;
      }
      cropper = new Cropper(previewImage, {
        viewMode,
        dragMode: 'move',
        minContainerWidth: Math.min(vw, config.width),
        minContainerHeight: Math.min(vh, originalImage.height),
        autoCropArea: 1,
        checkCrossOrigin: false,
        data: cropBoxData,
        ready() {
          if (callback) {
            callback($element);
          }
        },
        crop(event) {
          if (event.detail.width > config.width) {
            event.preventDefault();
          }
          fixCrossOriginUrl();
        },
      });
    }

    function open() {
      if (config.height === 0) {
        createCropperWithoutAspectRatio(
          CROPPER_VIEW_MODE_CROP_RESTRICTED_TO_CANVAS,
          openImageReveal,
        );
        $('.cropit-image', $element).hide();
        $('.cropit-spacer', $element).hide();
        $('.cropit-wrapper', $element).css({
          maxHeight: '',
          maxWidth: '',
        });
      } else {
        createCropper(CROPPER_VIEW_MODE_CROP_RESTRICTED_TO_CANVAS, openImageReveal);
        $('.cropit-image', $element).hide();
        $('.cropit-spacer', $element).hide();
        $('.cropit-wrapper', $element).css({
          maxHeight: '',
          maxWidth: '',
        });
        $('.cropit-preview-container', $element).css('height', '100%');
        $('.cropit-preview', $element).css('height', '100%');
        $('.cropit-preview-image', $element).css({
          height: '100%',
          width: '100%',
        });
      }
    }

    function close() {
      cropper.destroy();
      cropper = null;
      $element.removeClass(cssClass.changed);
      closeImageReveal($element);
      $element.closest('.reveal__inner').css('overflow-y', 'auto');
      $('.cropit-image', $element).show();
      $('.cropit-wrapper', $element).css({
        maxHeight: config.height !== 0 ? config.height : '',
        width: '',
        height: '',
      });
      $('.cropit-preview-container', $element).css('height', '');
      $('.cropit-preview', $element).css({
        height: '',
        maxHeight: '',
      });
      $('.cropit-preview-image', $element).css('height', '');
      $('[data-cropit-toggle-minzoom]', $element)
        .removeClass(cssClass.minzoomFit)
        .addClass(cssClass.minzoomFill);
    }

    if (input) {
      $(input).on('change', (e) => {
        const { files } = e.target;
        /**
         *
         * @param {string} url
         */
        const done = (url) => {
          input.value = '';
          previewImage.src = url;
          originalImage.src = url;
          cropBoxData = {};
          $element.find('img').attr('src', url);
          $element.find('img').css('width', config.width);
          if (config.height !== 0) {
            $element.find('img').css('max-height', config.height);
          }

          $element.addClass(cssClass.hasimage);
          $addImageButton.hide();

          if (config.compact) {
            $element.show();
          }

          open();
        };

        if (files && files.length > 0) {
          const file = files[0];

          if (URL) {
            done(URL.createObjectURL(file));
          } else if (FileReader) {
            const reader = new FileReader();
            reader.onload = () => {
              done(reader.result);
            };
            reader.readAsDataURL(file);
          } else {
            imageError('Cannot read file properly!');
          }
        }
      });
    } else {
      imageError('No image field!');
    }

    $('[data-cropit-toggle-image-editor]', $element).click(() => {
      open();
    });

    function uploading($button) {
      $('.cropit').addClass('cropit--loading');
      $button.attr('disabled', true);
    }

    function doneUploading($button) {
      $('.cropit').removeClass('cropit--loading');
      $button.removeAttr('disabled');
    }

    $('[data-cropit-save]', $element).click((e) => {
      uploading($(e.target));
      const canvas = cropper.getCroppedCanvas({ width: config.width, height: config.height });
      const uploadUrl = $(e.target).data('upload-url');
      const croppedImage = canvas.toDataURL();
      if (!uploadUrl) {
        $element.find('input[type="hidden"]').val(JSON.stringify({
          data: croppedImage,
          name: 'image.png',
        })).trigger('change');
      } else {
        canvas.toBlob(async (image) => {
          const formData = new FormData($(e.target).closest('form')[0]);
          formData.append('image.png', image, 'image.jpg');
          const response = await fetch(uploadUrl, { method: 'post', body: formData });
          const json = await response.json();
          if (json.url) {
            $element.find('input[type="hidden"]').val(json.url).trigger('change');
            doneUploading($(e.target));
            close();
          } else {
            imageError(json.message);
          }
        }, 'image/jpeg', 0.8);
      }

      if (originalImage.src !== '') {
        originalImage.src = previewImage.src;
      }
      $element.find('img').attr('src', croppedImage);
      previewImage.onload = (event) => {
        $('.cropit-wrapper', $element).css({
          maxWidth: event.currentTarget.naturalWidth,
        });
      };
      cropBoxData = cropper.getData();
      canvasData = cropper.getCanvasData();
      if (!uploadUrl) {
        doneUploading($(e.target));
        close();
      }
    });

    $('[data-cropit-cancel]', $element).click(() => {
      previewImage.src = previouslyCroppedImage;
      $('.cropit-wrapper', $element).css({
        maxWidth: previewImage.naturalWidth,
      });
      // remove image when cancelling initial crop
      if (!$element.find('input[type="hidden"]').val()) {
        $('[data-cropit-remove]', $element).trigger('click');
      }
      close();
    });

    $('[data-cropit-remove]', $element).click(() => {
      $element.find('input[type="file"]').val('');
      $element.find('input[type="hidden"]').val('');
      $element.removeClass(cssClass.hasimage);
      $element.find('img').attr('src', '');
      $('.cropit-spacer', $element).show();

      if (config.compact) {
        $addImageButton.show();
        $element.hide();
      }
    });

    $('[data-cropit-rotate-right]', $element).click(() => {
      cropper.rotate(90);
    });

    $addImageButton.click(() => {
      const name = $addImageButton.data('cropit-id');
      $(`input[id="${name}"]`).trigger('click');
    });

    $('[data-cropit-range-decrease]', $element).click(() => {
      cropper.zoom(-0.1);
    });

    $('[data-cropit-range-increase]', $element).click(() => {
      cropper.zoom(0.1);
    });

    $('[data-cropit-toggle-minzoom]', $element).click((e) => {
      const target = $(e.currentTarget);
      let viewMode;
      if (target.hasClass(cssClass.minzoomFit)) {
        viewMode = CROPPER_VIEW_MODE_CROP_RESTRICTED_TO_CANVAS;
        target.removeClass(cssClass.minzoomFit).addClass(cssClass.minzoomFill);
      } else {
        viewMode = CROPPER_VIEW_MODE_NO_CROP_RESTRICTIONS;
        target.removeClass(cssClass.minzoomFill).addClass(cssClass.minzoomFit);
      }
      cropper.destroy();
      if (config.height === 0) {
        createCropperWithoutAspectRatio(viewMode);
      } else {
        createCropper(viewMode);
      }
    });
  });
}
