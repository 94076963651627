if (!window.easyLms) {
  window.easyLms = {};
}

window.easyLms.validation = {
  oneToMany: function oneToMany(value, messages, options) {
    const numberOfItems = $(options.itemSelector).length;

    if (options.min !== null && numberOfItems < options.min) {
      messages.push(options.tooFew);
    }

    if (options.max !== null && numberOfItems > options.max) {
      messages.push(options.tooMany);
    }
  },
};
