export default function () {
  $('[data-submit]').each((index, clickElement) => initialize($(clickElement)));
}

function initialize($clickElement) {
  $clickElement.on('click', (event) => {
    const $clickedElement = $(event.target);
    $($clickedElement.data('submit')).submit(function (eventObj) {
      $(this).append('<input type="hidden" name="' + $clickedElement.attr('name') + '" value="' + $clickedElement.val() + '" /> ');
      return true;
    }).submit();
  });
}