export default function () {
  $(document).on('click', '[data-update-attributes]', (e) => {
    e.preventDefault();

    const $element = $(e.currentTarget);
    const config = $element.data('update-attributes');

    $.each(config.attributes, (index, item) => {
      $(item.target).html(item.value);
    });
  });
}
