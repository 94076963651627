import 'rangeslider.js';
import 'flatpickr';
import 'autosize';
import './base/index';

import initNavigationDropdown from './dashboard/navigation-dropdown';
import initSortableGridView from './dashboard/sortable-grid-view';
import initShowIf from './dashboard/show-if';
import replace from './dashboard/replace';
import initQuestionsMultipleCorrectAnswers from './dashboard/questions-multiple-correct-answers';
import initGridViewLoader from './dashboard/grid-view-loader';
import initDropdown from './dashboard/dropdown';
import revealModal from './dashboard/reveal-modal';
import initBulkActions from './dashboard/bulkactions';
import initSelectText from './dashboard/select-text';
import formPjaxSubmit from './dashboard/form-pjax-submit';
import pjaxRefresh from './dashboard/pjax-refresh';
import pjaxUpdate from './dashboard/pjax-update';
import initPjaxCloseModal from './dashboard/pjax-close-modal';
import initForm from './dashboard/form/index';
import offCanvas from './dashboard/off-canvas';
import pjaxButton from './dashboard/pjax-button';
import learningContentModal from './dashboard/learning-content-modal';
import dialcode from './dashboard/dialcode';
import updateAttributes from './dashboard/update-attributes';
import update from './dashboard/update';
import pjaxTrigger from './dashboard/pjax-trigger';
import showAccessCode from './dashboard/examAccessCode/exam-access-code';
import showImportResultButton from './dashboard/showImportResultButton.js';
import initTabLoader from './dashboard/tab-loader';
import formAnchorRestore from './dashboard/form-anchor-restore';
import initGridViewFilter from './dashboard/grid-view-filter';
import initGridViewSelectSorter from './dashboard/grid-view-select-sorter';

initShowIf();
pjaxButton();
initNavigationDropdown();
initSortableGridView();
initQuestionsMultipleCorrectAnswers();
initGridViewLoader();
replace();
offCanvas();
initBulkActions();
initSelectText();
formPjaxSubmit();
pjaxRefresh();
pjaxUpdate();
initPjaxCloseModal();
initForm();
revealModal();
initDropdown();
learningContentModal();
dialcode();
updateAttributes();
update();
initTabLoader();
pjaxTrigger();
showAccessCode();
showImportResultButton();
formAnchorRestore();
initGridViewFilter();
initGridViewSelectSorter();
