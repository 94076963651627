export const cssClass = {
  hasimage: 'cropit--hasimage',
  reveal: 'cropit--reveal',
  loading: 'cropit--loading',
  changed: 'cropit--changed',
  minzoomFit: 'cropit-toggle-minzoom--fit',
  minzoomFill: 'cropit-toggle-minzoom--fill',
  revealOpen: 'reveal-open',
};

export function imageError(message) {
  $.snackbar({
    content: message,
    timeout: 6000,
    htmlAllowed: true,
  });
}

export function openImageReveal($container) {
  // Set parent size to prevent scroll jumping
  $container.closest('[data-imagefield-wrapper]').css({ height: $container.height(), width: $container.width() });
  // Add the class to give it Reveal appearance
  $container.addClass(cssClass.reveal);
}

export function closeImageReveal($container) {
  // Set parent size to prevent scroll jumping
  $container.closest('[data-imagefield-wrapper]').removeAttr('style');
  // Add the class to give it Reveal appearance
  $container.removeClass(cssClass.reveal);
}
