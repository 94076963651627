function initialize() {
  $(document).on('pjax:success', (xhr) => {
    const $target = $(xhr.target);
    if ($target.length && $target.data('pjax-update') && $target.find('[data-form]')) {
      $($target.data('pjax-update')).each((key, target) => {
        const postLink = $(target).find('[data-params]');
        const data = $target.find('[data-form]').data('form');
        postLink.data('params', data);
      });
    }
  });
}

export default function () {
  if ($('[data-pjax-update]').length) {
    initialize();
  }
}
