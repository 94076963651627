import tinymce from 'tinymce';

// Plugin needs to be constructable, so we cannot have an arrow function.
// eslint-disable-next-line prefer-arrow-callback
tinymce.PluginManager.add('contenttags', function Plugin(editor) {
  // Add a button that opens a window
  const config = editor.getParam('content_tags');
  if (config.tags && config.label) {
    const contentTags = config.tags;
    const textLabel = config.label;
    const listBoxValues = Object
      .keys(contentTags)
      .map((key) => ({
        text: contentTags[key],
        value: key,
      }));
    if (listBoxValues.length) {
      editor.ui.registry.addButton('tag', {
        text: textLabel,
        icon: 'code-sample',
        onAction: () => {
          // Open window
          editor.windowManager.open({
            title: textLabel,
            body: {
              type: 'panel',
              items: [
                {
                  type: 'selectbox',
                  name: 'contentTagSelect',
                  label: textLabel,
                  items: listBoxValues,
                },
              ],
            },
            buttons: [
              {
                type: 'cancel',
                name: 'cancel',
                text: 'Cancel',
              },
              {
                type: 'submit',
                name: 'submitButton',
                text: 'Ok',
                primary: true,
              },
            ],
            onSubmit: (dialogApi) => {
              // Insert content when the window form is submitted
              editor.insertContent(dialogApi.getData().contentTagSelect);
              dialogApi.close();
            },
          });
        },
      });
    }
  }
});
