import refresh from './refresh';

function initialize($element) {
  const config = $element.data('checkbox-value-if');
  const $target = $(config.target);
  const $compareValue = config.compareValue;
  const $setValue = config.value;

  const update = (value) => {
    if (value === Boolean($compareValue)) {
      $element.find('input:checkbox:first').prop('checked', $setValue);
      refresh();
    }
  };

  if ($target.length) {
    update($target.prop('checked'));
  } else {
    $element.update();
  }

  $(document).on('change', config.target, (event) => {
    const $changeTarget = $(event.currentTarget);
    update($changeTarget.prop('checked'));
  });
  $(config.target).trigger('change');
}

export default function () {
  $('[data-checkbox-value-if]').each((index, element) => initialize($(element)));
}
