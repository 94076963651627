export default function () {
  $('[data-file-input]').each((index, input) => {
    const $input = $(input);
    const $label = $(`label[for="${input.id}"]`);
    const baseLabel = $label.children('span').text();

    $input
      .on('change', (ev) => {
        const file = ev.target.value.replace(/^.*\\/, '');

        $label[file ? 'addClass' : 'removeClass']('inputfile-label--uploaded')
          .find('span')
          .text(file || baseLabel);
      })
      .on('focus', () => $input.addClass('has-focus'))
      .on('blur', () => $input.removeClass('has-focus'));


    const $form = $input.closest('form');
    $form.on('beforeSubmit', (event, messages, errors) => {
      $('[type="submit"]', $form)
         .prop('disabled', true)
         .addClass('button--loading');
    });
  });

}
