import initAceEditor from './ace-editor';
import initColorPicker from './color-picker';
import initDateTimePicker from './date-picker';
import initFileInput from './file-input';
import initPopupRadio from './popup-radio';
import initTimePicker from './time-picker';
import initTimeZoneInput from './timezone-input';
import initToggleInput from './toggle-input';
import initCheckbox from './checkbox';
import refresh from './refresh';

export default () => {
  initColorPicker();
  initDateTimePicker();
  initPopupRadio();
  initAceEditor();
  initFileInput();
  initToggleInput();
  initTimeZoneInput();
  initTimePicker();
  initCheckbox();
  refresh();
}

