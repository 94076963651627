import refresh from './form/refresh';

export default function () {
  $('[data-show-if]').each((index, element) => initialize($(element)));
  $(document).on('pjax:complete', (xhr) => {
    $('[data-show-if]', $(xhr.target)).each((index, element) => initialize($(element)));
  });
}

function includes(allowed, value) {
  return value == allowed || Array.isArray(allowed) && allowed.includes(value);
}

function invertCondition(invert, fn) {
  return invert ? (value) => !fn(value) : fn;
}

function initialize($element) {
  const config = $element.data('show-if');
  const $target = $(config.target);

  const isVisible = config.value === undefined
    ? (value) => value
    : invertCondition(!config.equals, (value) => includes(config.value, value));

  const update = (value) => {
    if (isVisible(value)) {
      $element.slideDown(200, function () {
        $element.css('overflow', 'visible');
      });
      refresh();
    } else {
      $element.slideUp(200);
    }
  };
  const init = (value) => {
    if (isVisible(value)) {
      $element.show();
      refresh();
    } else {
      $element.hide();
    }
  };

  if ($target.length) {
    if ($target.is(':checkbox')) {
      init($target.prop('checked'));
      //@todo: solution to fix possibility that there are multiple values here, check if we can use this
    } else if ($target.is(':input[type="radio"]')) {
      $target.each(function() {
        if($(this).prop('checked')) {
          init($(this).val());
          return;
        }
      });
    } else if ($target.is(':input') || $target.is('select')) {
      init($target.val());
    } else {
      init($('input[type="radio"]:checked', $target).val());
    }
  } else {
    $element.hide();
  }

  $(document).on('change', config.target, (event) => {
    const $target = $(event.currentTarget);
    if ($target.is(':checkbox')) {
      update($target.prop('checked'));
    } else if ($target.is(':input')) {
      update($target.val());
    } else {
      update($('input[type="radio"]:checked', $target).val());
    }
  });
  //change this to a check if the selector yields multiple results, and if so, iterate over all the results and only trigger the change on the item that is selected...
  if (!$target.is(':radio')) {
    $(config.target).trigger('change');
  }
}

