export default () => {
  $('[data-reveal][data-reveal-before-submit]').each(
    (index, element) => initialize($(element), $(element).data('reveal-before-submit'))
  );
};

function initialize($container, config) {
  const $form = $(config.form);
  let triggerModal = true;

  $.each(config.buttons, (key, values) => {
   // console.log($(key, $container).length);
    $(key, $container).click((event) => {
      event.preventDefault();
      $.each(values, (name, value) => {
        $(`[name*="${name}"]`, $form).val(value);
      });
      triggerModal = false;
      $form.submit();
    });
  });

  $form.on('beforeSubmit', (event) => {
    if (triggerModal) {
        $container.foundation('open');
        return false;
    }
    return true;
  });
}
