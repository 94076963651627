export default function() {
  $('select[data-set-on-select]').on('change', (ev) => {
    const select = ev.target;
    const toChange = $(select.options[select.selectedIndex]).data('set-on-select');

    for (let name in toChange) {
      let field = $("[name$=\"[" + name + "]\"]");
      field.val(toChange[name]).change();
    }
  });
}
