function initialize($select) {
  $select.removeClass().addClass($select.val() && 'formcontrol--hasvalue');
}

export default function () {
  $('.grid-view .filters select').each((index, element) => initialize($(element)));
  $('[data-pjax-container]').on('pjax:end', () => {
    $('.grid-view .filters select').each((index, element) => initialize($(element)));
  });
}
