import refresh from './refresh';
import showIf from '../show-if'

export default function () {
  $(document).on('click', '[data-update-form]', (event) => {
    event.preventDefault();

    const $element = $(event.currentTarget);
    const config = $element.data('update-form');
    const $target = $(config.target);

    $.each(config.attributes, (index, value) => {
      const $checkbox = $(`[name*="[${index}]"][type="checkbox"]`, $target);
      if ($checkbox.length) {
        $checkbox.prop('checked', value);
      } else {
        $(`[name*="[${index}]"],[name="${index}"]`, $target).each((_, element) => {
          $(element).val(value);
          if ($(element).attr('data-select2-input')) {
            $(element).select2();
          }
        });
      }
    });

    showIf();
    refresh();
  })
}
