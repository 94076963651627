function initUpdate() {
  const $tabs = $('[data-tab-field]');
  if ($tabs.data('deep-link')) {
    $tabs.on('change.zf.tabs', () => {
      document.getElementById($tabs.data('tab-field')).value = window.location.hash.substr(1);
    });
  }
}

function initialize() {
  $(document).on('pjax:complete', () => {
    $('[data-pjax-tab-container]').foundation();
    initUpdate();
  });
  initUpdate();
}

export default function () {
  if ($('[data-pjax-container]').length && $('[data-pjax-tab-container]').length) {
    initialize();
  }
}
