export default function () {
  $('[data-pjax-trigger]').each((index, element) => {
    const $element = $(element);
    const config = $element.data('pjax-trigger');
    $element.change(() => {
      let url = config.url;
      url += ((url.indexOf('?') >= 0) ? '&' : '?') + config.paramName + '=' + $element.val();
      $.pjax({ url: url, container: config.target, timeout: 10000 })
    });
  });
}
