import { Foundation } from 'foundation-sites/js/foundation.core';

export default function () {
  if ($('[data-pjax-container]').length) {
    initialize();
  }
}

function initialize() {
  $(document).on('pjax:send', function (xhr, options) {
    $(xhr.target).addClass('pjax--loading').attr('aria-busy', 'true');
  });
  $(document).on('pjax:complete', function (xhr, textStatus, options) {
     $($('[data-open]', xhr.target)).each((index, item) => {
      $(item).attr('aria-controls', $(item).data('open'));
      $(item).attr('aria-haspopup', 'true');
    });
    $(xhr.target).foundation();
    $(xhr.target).removeClass('pjax--loading').attr('aria-busy', 'false');
  });
}
