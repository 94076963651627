const $timePickers = $('[data-timepicker]');
const defaultConfig = {
  allowInput: true,
  enableTime: true,
  enableSeconds: true,
  disableMobile: true,
  noCalendar: true,
  time_24hr: true,
  hourIncrement: 1,
  minuteIncrement: 1,
  secondIncrement: 1,
  defaultHour: 0,
};

function disableInput(timeId) {
  const $element = $(`.flatpickr-${timeId}`);
  const $parent = $element.parent();
  $parent.css({ 'background-color': '#eeeeee' });
  $element.css({ color: '#aaaaaa' });
  $parent.children('span').css({ cursor: 'not-allowed' });
}

function disableInputs(config) {
  Object.entries(config).forEach(([key, value]) => {
    if (key.includes('Increment') && !value) {
      disableInput(key.replace('Increment', ''));
    }
  });
}

function initialize() {
  $timePickers.each((index, element) => {
    const config = $.extend({}, defaultConfig, $(element).data('timepicker'));
    element.classList.add('flatpickr-input');
    element.flatpickr(config);
    disableInputs(config);
  });
}

export default () => {
  if ($timePickers.length) {
    initialize();
  }
};
