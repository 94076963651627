export default () => {
  $(document).on('pjax:success', (xhr) => {
    close($(xhr.target));
    close($(xhr.relatedTarget));
  });
};

function close($target) {
  if ($target.is('[data-pjax-close-modal]')) {
    $target.closest('[data-reveal]').foundation('close');
  }
}
